<template>
  <div class="admin-wrapper">
    <div class="news">
      <div class="news-header">
        <div class="left">
          <div class="select-tab">
            <div
              v-for="(item, index) in categoryList"
              :key="index"
              class="tabs"
              @click="handleChoseCategory(item)"
              :class="[category == item.label ? 'active' : '']"
            >
              {{ item.value }}
            </div>
          </div>
        </div>
        <div class="right">
          <div class="search">
            <el-input
              v-model="keyword"
              placeholder="搜索"
              size="small"
              @input="handleInput"
              @keyup.enter.native="handleSearch"
            >
              <!-- @input="handleInput"
                @keyup.enter.native="handleSearch" -->
              <i
                class="el-icon-search el-input__icon"
                slot="suffix"
                @click="handleSearch"
              ></i>
            </el-input>
          </div>
          <div>
            <div class="time-wrapper">
              <span class="label">日期筛选：</span>
              <el-date-picker
                v-model="selectTime"
                type="date"
                value-format="yyyy-MM-dd"
                size="small"
                placeholder="请选择日期"
                @change="handleSeletTime"
              >
              </el-date-picker>
            </div>
          </div>
        </div>
      </div>
      <div class="filter-area">
        <div class="item-wrapper">
          <div class="item-key">
            <strong>关键字：</strong>
          </div>
          <div class="item-value" :class="[testField ? '' : 'extend']">
            <div class="value">
              <span>value1</span>
            </div>
            <div class="value"><span>value1</span></div>
            <div class="value"><span>value2</span></div>
            <div class="value"><span>value3</span></div>
            <div class="value"><span>value24</span></div>
            <div class="value"><span>value25</span></div>
            <div class="value"><span>value26</span></div>
            <div class="value"><span>value27</span></div>
            <div class="value"><span>value28</span></div>
            <div class="value"><span>value28</span></div>
            <div class="value"><span>value28</span></div>
            <div class="value"><span>value28</span></div>
            <div class="value"><span>value28</span></div>
            <div class="value"><span>value28</span></div>
            <div class="value"><span>value28</span></div>
          </div>
          <div class="item-action">
            <div class="more" @click="handleMoreValue">
              {{ testField ? "更多" : "收起" }}
            </div>
          </div>
        </div>
      </div>
      <el-table
        :data="newsList"
        style="width: 100%"
        row-key="id"
        @selection-change="handleSelectTableList"
      >
        <el-table-column
          type="selection"
          reserve-selection
          width="55"
          align="center"
        >
        </el-table-column>
        <el-table-column label="序号" type="index" width="65">
        </el-table-column>
        <el-table-column prop="date" label="标签" width="120">
          <template slot-scope="{ row }">
            <div class="catogory">
              【{{
                row.category == 0
                  ? "未知"
                  : row.category == 7
                  ? "政策观察"
                  : row.category == 8
                  ? "公司动态"
                  : row.category == 9
                  ? "行业新闻"
                  : row.category == 10
                  ? "国内外文献"
                  : row.category == 11
                  ? "专利跟踪"
                  : ""
              }}】
            </div>
          </template>
        </el-table-column>
        <el-table-column
          class="title"
          prop="title"
          label="标题"
          show-overflow-tooltip
        >
          <template slot-scope="{ row }">
            <span
              v-html="row.title"
              @click="handleViewDetail(row)"
              style="cursor: pointer"
            ></span>
          </template>
        </el-table-column>

        <el-table-column
          prop="source"
          label="来源"
          width="120"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column prop="send_time" label="时间" width="180">
        </el-table-column>
        <el-table-column label="操作" width="200">
          <template slot-scope="{ row }">
            <div class="news-btn">
              <span
                class="btn_hover type--text ml-0"
                @click="handleViewDetail(row)"
                style="cursor: pointer"
                >查看</span
              >
              <!-- <span v-if="!row.verify_status" class="btn_hover type--text ml-0"  @click="handlePublishNews(row)">发布</span>
          <span v-else class="btn_hover type--text ml-0"  disabled>已发布</span>
          <span  v-if="row.verify_status" class="btn_hover type--text ml-0" @click="handleRevokeNews(row)">撤回</span>
          <span  v-else class="btn_hover type--text ml-0" disabled>已撤回</span> -->
              <span class="btn_hover type--text ml-0" @click="handleDelNew(row)"
                >删除</span
              >
              <!-- <span
                class="btn_hover type--text ml-0"
                @click="handleSetCategory(row)"
                >设置类别</span
              >  -->
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div :class="['more', { loading: loading }]" v-loading="loading">
      <span v-show="scrollCount > 2 && more" @click="handleMore">加载更多</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "newPowerSystem",

  data() {
    return {
      testField: true,
      more: true,
      scrollCount: 0,
      page: 1,
      loading: false,
      start_time: "",
      timeout: null,
      limit: 20,
      selectTime: "",
      newsList: [],
      keyword: "",
      seleTableList: [],
      categoryList: [
        { label: 7, value: "政策观察" },
        { label: 8, value: "公司动态" },
        { label: 9, value: "行业新闻" },
        { label: 10, value: "国内外文献" },
        { label: 11, value: "专利跟踪" },
      ],
      category: "",
    };
  },
  watch: {
    selectTime(val) {
      console.log("val", val);
      this.handleSearch();
    },
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
    userInfo() {
      return this.$store.state.userInfo;
    },
    isDisableAuth() {
      return this.userInfo.username == "houwenzhao";
    },
  },
  activated() {
    this.loading = false;
    this.more = true;
    this.scrollCount = 0;
    this.handleSearch();
    window.addEventListener("scroll", this.handleScroll);
  },
  deactivated() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleAuthBtn() {
      if (this.isDisableAuth) {
        this.$message.warning("该账号没有此权限");
        return false;
      }
      return true;
    },
    async getNews(start_time) {
      // start_time  !== 'undefined' 刷新
      // start_time  === 'undefined' 更多
      let res = await this.api.postFormAPISM(
        {
          keywords: this.keyword || "",
          start_time:
            typeof start_time !== "undefined"
              ? start_time
              : this.newsList[this.newsList.length - 1].send_time,
          limit:
            typeof start_time !== "undefined"
              ? this.keyword == "" || this.selectTime == ""
                ? this.limit
                : this.newsList.length
              : this.limit,
          select_date: this.selectTime ? this.selectTime : "",
        },
        "/admins/getnewarticlelist"
      );

      console.log("getNews");
      return res.data.data.article_list.map(item => {
        return {
          ...item,
          title: item.title.replace(
            this.keyword,
            `<span style="color:red">${this.keyword}</span>`
          ),
        };
      });
      // this.start_time = this.newsList[this.newsList.length - 1].send_time;
    },
    async handleSearch() {
      this.newsList = await this.getNews("");
    },

    handleInput(val) {
      // console.log("handleInput", val, this.keyword);
      if (this.timeout) window.clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.handleSearch();
        this.timeout = null;
      }, 1000);
    },

    async handleMore(text) {
      this.tableLoading = true;
      this.page++;
      console.log(this.page);
      let data = await this.getNews();
      this.newsList = [...this.newsList, ...data];
      this.more = data.length === this.limit;
      if (text) {
        this.loading = false;
        this.scrollCount++;
      }
    },
    handleScroll() {
      if (!this.newsList.length || this.loading || this.scrollCount > 2) return;

      // 距顶部
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      // 可视区高度
      var clientHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      // 滚动条总高度
      var scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      let targetHeight = scrollHeight - 257 - 140;
      // console.log(scrollTop, clientHeight, scrollHeight, targetHeight,  targetHeight - scrollTop - clientHeight);
      if (targetHeight - scrollTop - clientHeight < 10) {
        this.loading = true;
        // return
        setTimeout(() => {
          this.handleMore("scroll");
        }, 500);
      }
    },
    handleViewDetail({ id }) {
      let text = this.$router.resolve({
        path: "/newsDetail",
        query: {
          id,
          article_type: 1,
        },
      });
      console.log("text", text.href);
      // 打开一个新的页面
      window.open(text.href, "_blank");
    },
    handlePublishNews({ id, verify_status }) {
      this.handleLogin();
      if (!this.token) return;
      if (!this.handleAuthBtn()) return;
      if (verify_status) {
        this.$message({
          message: "已发布，无需再发布",
          type: "warning",
        });
        return;
      }
      this.api
        .postFormAPISM(
          {
            id,
          },
          "/admins/pulisharticle"
        )
        .then(res => {
          this.$message({
            message: "发布成功",
            type: "success",
          });

          this.handleSearch();
          this.$forceUpdate();
        })
        .catch(error => {
          this.$message({
            message: "发布失败",
            type: "warning",
          });
        });
    },
    handleRevokeNews({ id, verify_status }) {
      console.log("handleRevokeNews", id);
      this.handleLogin();
      if (!this.token) return;
      if (!this.handleAuthBtn()) return;
      if (!verify_status) {
        this.$message({
          message: "已撤回，无需再撤回",
          type: "warning",
        });
        return;
      }
      this.api
        .postFormAPISM(
          {
            id,
            id_list: [],
          },
          "/admins/revokearticle"
        )
        .then(res => {
          this.$message({
            message: "撤回成功",
            type: "success",
          });
          this.handleSearch();
          this.$forceUpdate();
        })
        .catch(error => {
          this.$message({
            message: "撤回失败",
            type: "warning",
          });
        });
    },
    handleLogin() {
      if (!this.token) {
        this.$message({
          type: "warning",
          message: "请先登录",
        });
        return this.handleOpenLogin();
      }
    },
    handleOpenLogin() {
      this.$login.show();
    },
    // 删除
    async handleDelNew({ id }) {
      this.handleLogin();
      if (!this.token) return;
      if (!this.handleAuthBtn()) return;
      let comfirmPromise = new Promise(resolve => {
        this.$confirm("您确定要删除吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          confirmButtonClass: "butText",
          type: "warning",
        })
          .then(() => {
            resolve(true);
          })
          .catch(() => {
            resolve(false);
          });
      });
      let confirm = await comfirmPromise;
      if (!confirm) return;
      this.api
        .postFormAPISM(
          {
            id,
            article_type: 1,
          },
          "/admins/deletearticle"
        )
        .then(res => {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
          this.handleSearch();
        })
        .catch(error => {
          console.log("error", error);
        });
    },
    handleMoreValue() {
      this.testField = !this.testField;
    },
    handleChoseCategory(item) {
      console.log(item);
      this.category = item.label;
      let article_id = this.seleTableList.map(item => item.id);
      if (this.seleTableList.length) {
        this.handleLogin();
        if (!this.token) return;
        if (!this.handleAuthBtn()) return;
        this.api
          .postFormAPISM(
            {
              article_id,
              category: item.label,
              article_type: 1,
            },
            "/admins/setarticlecategory"
          )
          .then(res => {
            this.$message({
              type: "success",
              message: "设置类别成功",
            });
            this.handleSearch();
          })
          .catch(error => {
            console.log("error", error);
          });
      } else {
      }
    },
    handleSetCategory({ id, category }) {
      console.log("handleSetCategorysss", category);
      this.handleLogin();
      if (!this.token) return;
      if (!this.handleAuthBtn()) return;
      this.$choiceCatogry.show({
        title: "设置类别",
        category: "" + category,
        categoryList: this.categoryList,
        successCallback: catogoryValue => {
          this.api
            .postFormAPISM(
              {
                article_id: id,
                category: catogoryValue,
                article_type: 1,
              },
              "/admins/setarticlecategory"
            )
            .then(res => {
              this.$message({
                type: "success",
                message: "设置类别成功",
              });
              this.handleSearch();
            })
            .catch(error => {
              console.log("error", error);
            });
        },
      });
    },
    handleSeletTime(select_date) {
      this.selectTime = select_date;
    },
    handleSelectTableList(val) {
      this.seleTableList = val;
      this.$EventBus.$emit("seleTableList", val);
    },
  },
};
</script>

<style lang="less" scoped>
.admin-wrapper {
  width: 1200px;
  overflow: hidden;
  padding: 20px 0 0;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  & > .news {
    & > .news-header {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      font-size: 14px;
      justify-content: space-between;
      margin-bottom: 20px;
      > .left {
        // padding-left: 15px;
        > .select-tab {
          display: flex;
          > .tabs {
            cursor: pointer;
            margin-right: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 76px;
            height: 29px;
            border: 1px solid #d1d1d1;
            font-size: 14px;
            color: #333;
          }
          > .active {
            background: #058e44;
            color: white;
          }
        }
      }
      > .right {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        font-size: 14px;
        justify-content: flex-end;
        // margin-bottom: 50px;
        & > .time-wrapper {
        }
        & /deep/ .search {
          margin-right: 30px;

          & > .el-input {
            width: 250px;
            & > .el-input__inner {
              border-radius: 30px;
              &:focus,
              &:hover {
                // border-color: transparent;
                // outline: unset;
              }
              &::placeholder {
                color: rgb(117, 117, 117);
              }
            }
            & i {
              color: #4471bc;
              font-size: 14px;
              cursor: pointer;
            }
          }
        }
      }
    }
    > .filter-area {
      border-top: 1px solid #ddd;
      margin-bottom: 10px;
      > .item-wrapper {
        border-bottom: 1px solid #ddd;
        line-height: 34px;
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        > .item-key {
          width: 100px;
          padding-left: 10px;
          white-space: nowrap;
          overflow: hidden;
          color: #666;
          background: #f3f3f3;
        }
        > .item-value {
          padding-left: 10px;
          // display: flex;
          // align-items: center;
          height: 30px;
          overflow: hidden;
          > .value {
            float: left;
            width: 130px;
            overflow: hidden;
            > span {
              cursor: pointer;
              &:hover {
                color: #058e44;
              }
            }
          }
        }
        > .extend {
          height: auto;
        }
        > .item-action {
          width: 110px;
          height: auto;
          margin-top: 5px;
          display: flex;
          > .more {
            width: 39px;
            height: 23px;
            border: 1px solid #ddd;
            padding: 0 7px 0 4px;
            display: flex;
            align-items: center;
            cursor: pointer;
            margin-right: 10px;
          }
        }
      }
    }
    & .el-table {
      & .catogory {
        color: #058e44;
      }
      & .news-btn {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        font-size: 14px;
        .btn_hover {
          margin: 0 10px 0 10px;
          cursor: pointer;
          &:first-of-type {
            margin-left: 0;
          }

          &.type--text {
            color: #409eff;
          }

          &:hover {
            color: #02b980;
          }

          &.not-allowed,
          &[disabled] {
            color: gray !important;
            cursor: not-allowed !important;
          }
        }
      }
    }
  }
  & > .more {
    padding: 10px 0 0;

    &.loading {
      padding: 35px 0 0;
    }

    & > span {
      display: block;
      padding: 2px 0;
      cursor: pointer;
      width: 296px;
      margin: 0 auto;
      text-align: center;
      font-family: "Arial Normal", "Arial", sans-serif;
      font-weight: 400;
      font-style: normal;
      // font-size: 13px;
      // color: #15a3f0;
      font-size: 14px;
      color: #058e44;
      background-color: rgb(230, 241, 251);
    }

    & .el-loading-spinner {
      margin: 0;
      top: 10px;

      & .circular {
        height: 26px;
        width: 26px;
      }
    }
  }
}
</style>